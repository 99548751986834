import React from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import UseCases from '../components/UseCases';
import ClientsSay from '../components/ClientsSay';
import Industries from '../components/Industries';
import Solutions from '../components/Solutions';
import Slider from '../components/Slider';
import Footer from '../components/Footer';

function Home() {
  return (
    <>
      <Header />
      <Banner />
      {/* <Features /> */}
      <UseCases />
      <ClientsSay />
      <Solutions />
      {/* <Industries /> */}
      <Slider />
      <Footer />
    </>
  );
}

export default Home;
