import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/innerPage.css'
import audience from '../images/fashion/1.png'
import store from '../images/fashion/2.png'
import benchmarking from '../images/fashion/3.png'
import promotion from '../images/fashion/4.png'
import fashion1 from '../images/fashion/slider/1.png'
import fashion2 from '../images/fashion/slider/2.png'
import fashion3 from '../images/fashion/slider/3.png'
import fashion4 from '../images/fashion/slider/4.png'
import fashioncasestudy from '../images/fashioncasestudy.png'
import ClientsSay from '../components/ClientsSay'
import ClientList from '../components/ClientList'
import styles from '../styles/Fashion.module.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import DemoForm from '../components/DemoForm'
function FashionApparel() {
	const [isOpenForm, setIsOpenForm] = useState(false)
	return (
		<>
			<Header />
			<div className='header'>
				<h1>Strategic Insights for Fashion & Apparel Expansion</h1>
				<p>
					Evaluate catchment potential and identify customer profiles that align
					with your brand. Leverage data-driven insights to choose prime
					locations, attract the right shoppers, and enhance your brand’s
					presence. Ensure your stores are where your ideal customers are,
					driving sales and loyalty.
				</p>
			</div>
			{/* <div className={styles['image-container']}>
				<img
					src={fashion1}
					alt=''
					className={`${styles['image']} ${styles['firstImage']}`}
				/>
				<img
					src={fashion2}
					alt=''
					className={`${styles['image']} ${styles['secondImage']}`}
				/>
				<img
					src={fashion3}
					alt=''
					className={`${styles['image']} ${styles['thirdImage']}`}
				/>
				<img
					src={fashion4}
					alt=''
					className={`${styles['image']} ${styles['fourthImage']}`}
				/>
			</div> */}
			{/* ${styles['slider-container']} ${styles['mobile-slider']} */}
			{/* <div
				className={[styles['slider-container'], styles['mobile-slider']].join(
					' ',
				)}>
				<Slider {...settings}>
					<img src={fashion1} alt='' className={styles['slick-img']} />

					<img
						src={fashion2}
						alt=''
						className={`${styles['slick-img']} ${styles['image-slider-2']}`}
					/>

					<img
						src={fashion3}
						alt=''
						className={`${styles['slick-img']} ${styles['image-slider']}`}
					/>
					<img
						src={fashion4}
						alt=''
						className={`${styles['slick-img']} ${styles['image-slider-4']}`}
					/>
				</Slider>
			</div> */}

			<div className='casestudy'>
				<div className='case-left'>
					<p className='title-case-study'>
						Case <span>Study</span>
					</p>
					<div className='inner-flex'>
						{' '}
						<h2>Accelerating Retail Expansion for a Leading Fashion Brand</h2>
						<p className='case-desc'>
							A leading fashion retailer, aiming to expand while avoiding store
							cannibalization, partnered with Sociometrik’s locational data
							intelligence for hyperlocal insights. These insights on consumer
							behavior, catchment demographics, and competitor activity helped
							the brand choose optimal expansion sites and run targeted
							marketing campaigns, driving footfall and minimizing risk in new
							locations.
						</p>
					</div>
					<button
						className='btn-case-study'
						onClick={() => setIsOpenForm(true)}>
						Read Full Case Study
					</button>
				</div>

				<img
					src={fashioncasestudy}
					alt='fashion-case-study'
					height={400}
					className={styles['case-img']}
				/>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						<span className='higlightText'>Targeted Store </span> Placement
					</h2>
					<p className='content'>
						Utilize location and consumer data to identify prime locations for
						new fashion stores. Ensure maximum foot traffic and visibility by
						targeting areas with high potential customer density.
					</p>
				</div>
				<div>
					<img src={audience} alt='' />
				</div>
			</div>
			<div className='imgRightContainer'>
				<div>
					<h2>
						Customer <span className='higlightText'> Insights and Trends</span>
					</h2>
					<p className='content'>
						Gain deep insights into customer preferences, purchasing behaviors,
						and fashion trends. Tailor your product offerings to meet evolving
						consumer demands and stay ahead of the competition.
					</p>
				</div>
				<div>
					<img src={store} alt='' />
				</div>
			</div>
			<div className='imgLeftContainer'>
				<div>
					<h2>
						Optimized{' '}
						<span className='higlightText'> Inventory Management</span>
					</h2>
					<p className='content'>
						Use real-time data to manage your inventory effectively. Ensure the
						right products are available at the right time, reducing overstock
						and stockouts, and improving customer satisfaction.
					</p>
				</div>
				<div>
					<img src={benchmarking} alt='' />
				</div>
			</div>
			<div className='imgRightContainer'>
				<div>
					<h2>
						<span className='higlightText'>Enhanced Marketing </span> Campaigns
					</h2>
					<p className='content'>
						Leverage data intelligence to create personalized marketing
						campaigns. Target specific consumer segments with tailored
						promotions, driving engagement, brand loyalty, and sales growth..
					</p>
				</div>
				<div>
					<img src={promotion} alt='' />
				</div>
			</div>

			<ClientsSay />
			<div style={{ marginBottom: '6rem' }}>
				<ClientList />
			</div>
			{isOpenForm && (
				<DemoForm
					setDemo={setIsOpenForm}
					isCaseStudyRequested={true}
					caseStudyRequested={'malls'}
				/>
			)}
			<Footer />
		</>
	)
}

export default FashionApparel
