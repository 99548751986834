import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/innerPage.css'
import styles from '../styles/Ethnicwear.module.css'
import audience from '../images/luxury/1.png'
import store from '../images/luxury/2.png'
import benchmarking from '../images/luxury/3.png'
import promotion from '../images/luxury/4.png'
import bridge1 from '../images/ethnicwear/1.png'
import bridge2 from '../images/ethnicwear/2.png'
import bridge3 from '../images/ethnicwear/3.png'
import ClientsSay from '../components/ClientsSay'
import ClientList from '../components/ClientList'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ethnicwearcasestudy from '../images/ethnicwearcasestudy.png'
import DemoForm from '../components/DemoForm'
function Ethnicwear() {
	const [isOpenForm, setIsOpenForm] = useState(false)
	return (
		<>
			<Header />
			<div className='header'>
				<h1>Discover Affluent Ethnicwear Hotspots</h1>
				<p>
					Uncover markets with a high concentration of affluent female shoppers
					seeking branded ethnic wear. Our insights help you strategically
					position your stores and tailor offerings, connecting you with the
					right audience for maximum impact.
				</p>
			</div>

			{/* <div className={styles['image-container']}>
				<img
					src={bridge1}
					alt=''
					className={`${styles['image']} ${styles['firstImage']}`}
				/>
				<img
					src={bridge2}
					alt=''
					className={`${styles['image']} ${styles['secondImage']}`}
				/>
				<img
					src={bridge3}
					alt=''
					className={`${styles['image']} ${styles['thirdImage']}`}
				/>
			</div>

			<div
				className={[styles['slider-container'], styles['mobile-slider']].join(
					' ',
				)}>
				<Slider {...settings}>
					<img src={bridge1} alt='' className={styles['slick-img']} />

					<img
						src={bridge2}
						alt=''
						className={`${styles['slick-img']} ${styles['image-slider-2']}`}
					/>

					<img
						src={bridge3}
						alt=''
						className={`${styles['slick-img']} ${styles['image-slider']}`}
					/>
				</Slider>
			</div> */}
			<div className='casestudy'>
				<div className='case-left'>
					<p className='title-case-study'>
						Case <span>Study</span>
					</p>
					<div className='inner-flex'>
						{' '}
						<h2>Expanding Reach for a Premium Ethnicwear Brand</h2>
						<p className='case-desc'>
							A premium ethnicwear brand partnered with Sociometrik’s AI-powered
							location intelligence platform to identify high streets frequented
							by affluent women with a taste for premium ethnicwear. Through
							hyperlocal data on demographics, consumer behavior, and competitor
							activity, the brand optimized its expansion strategy, targeting
							areas with high purchasing power and launching tailored marketing
							campaigns to reach their niche audience.
						</p>
					</div>
					<button
						className='btn-case-study'
						onClick={() => setIsOpenForm(true)}>
						Read Full Case Study
					</button>
				</div>

				<img
					src={ethnicwearcasestudy}
					alt='ethnicwear-case-study'
					height={400}
					className={styles['case-img']}
				/>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						<span className='higlightText'>Targeted Store </span> Placement
					</h2>
					<p className='content'>
						Utilize location and consumer data to identify prime locations for
						new fashion stores. Ensure maximum foot traffic and visibility by
						targeting areas with high potential customer density.
					</p>
				</div>
				<div>
					<img src={audience} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						Customer <span className='higlightText'> Insights and Trends</span>
					</h2>
					<p className='content'>
						Gain deep insights into customer preferences, purchasing behaviors,
						and fashion trends. Tailor your product offerings to meet evolving
						consumer demands and stay ahead of the competition.
					</p>
				</div>
				<div>
					<img src={store} alt='' />
				</div>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						Optimized{' '}
						<span className='higlightText'> Inventory Management</span>
					</h2>
					<p className='content'>
						Use real-time data to manage your inventory effectively. Ensure the
						right products are available at the right time, reducing overstock
						and stockouts, and improving customer satisfaction.
					</p>
				</div>
				<div>
					<img src={benchmarking} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						<span className='higlightText'>Enhanced Marketing </span> Campaigns
					</h2>
					<p className='content'>
						Leverage data intelligence to create personalized marketing
						campaigns. Target specific consumer segments with tailored
						promotions, driving engagement, brand loyalty, and sales growth..
					</p>
				</div>
				<div>
					<img src={promotion} alt='' />
				</div>
			</div>

			{/* <div className='casestudy'>
				<div className='case-left'>
					<p className='title-case-study'>
						Case <span>Study</span>
					</p>
					<div className='inner-flex'>
						{' '}
						<p>Expanding Reach for a Premium Ethnicwear Brand</p>
						<p className='case-desc'>
							A premium ethnicwear brand partnered with Sociometrik’s AI-powered
							location intelligence platform to identify high streets frequented
							by affluent women with a taste for premium ethnicwear. Through
							hyperlocal data on demographics, consumer behavior, and competitor
							activity, the brand optimized its expansion strategy, targeting
							areas with high purchasing power and launching tailored marketing
							campaigns to reach their niche audience.
						</p>
					</div>
					<button className='btn-case-study'>Read Full Case Study</button>
				</div>

				<img
					src={ethnicwearcasestudy}
					alt='ethnicwear-case-study'
					height={400}
					className='case-img'
				/>
			</div> */}
			<ClientsSay />
			<div style={{ marginBottom: '6rem' }}>
				<ClientList />
			</div>
			{isOpenForm && (
				<DemoForm
					setDemo={setIsOpenForm}
					isCaseStudyRequested={true}
					caseStudyRequested={'malls'}
				/>
			)}
			<Footer />
		</>
	)
}

export default Ethnicwear
