import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/innerPage.css'
import audience from '../images/jewellery/1.png'
import store from '../images/jewellery/2.png'
import benchmarking from '../images/jewellery/3.png'
import promotion from '../images/jewellery/4.png'
import jewel1 from '../images/jewellery/slider/1.png'
import jewel2 from '../images/jewellery/slider/jewellery.png'
import ClientsSay from '../components/ClientsSay'
import ClientList from '../components/ClientList'
import jewellerycasestudy from '../images/jewelerycasestudy.png'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styles from '../styles/Jewel.module.css'
import DemoForm from '../components/DemoForm'

function JewelleryWeddingWear() {
	const [isOpenForm, setIsOpenForm] = useState(false)
	return (
		<>
			<Header />
			<div className='header'>
				<h1>Identify top Jewelry & Weddingwear Clusters</h1>
				<p>
					Identify key wedding and jewelry clusters that attract brand-conscious
					shoppers with high purchasing power. Use data-driven insights to
					position your brand in the right locations, tapping into premium
					markets dominated by unbranded players. Elevate your brand's appeal
					and drive high-value sales in the competitive wedding and jewelry
					sectors.
				</p>
			</div>

			{/* <Carousel
				showArrows={false}
				autoPlay={true}
				showIndicators={false}
				infiniteLoop
				showStatus={false}
				showThumbs={false}
				dynamicHeight={true}>
				<div>
					<img src={jewel1} alt='qsr1' className={styles['carousel-img1']} />
				</div>
				<div>
					<img src={jewel2} alt='qsr2' className={styles['carousel-img2']} />
				</div>
			</Carousel> */}

			<div className='casestudy'>
				<div className='case-left'>
					<p className='title-case-study'>
						Case <span>Study</span>
					</p>
					<div className='inner-flex'>
						{' '}
						<h2>
							Finding Relevant Jewelry/Wedding Clusters for a Branded Jewellery
							Player
						</h2>
						<p className='case-desc'>
							A branded jewelry company partnered with Sociometrik’s locational
							data intelligence to identify highstreets frequented by affluent
							jewelry shoppers and analyze competitor performance in those
							areas. With hyperlocal insights into catchment demographics and
							spending patterns, the brand optimized store locations and
							launched targeted marketing campaigns, ensuring expansion into
							untapped, high-revenue markets.
						</p>
					</div>
					<button
						className='btn-case-study'
						onClick={() => setIsOpenForm(true)}>
						Read Full Case Study
					</button>
				</div>

				<img
					src={jewellerycasestudy}
					alt='jewellery-case-study'
					height={400}
					className={styles['case-img']}
				/>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						Market <span className='higlightText'> Demand Analysis </span>
					</h2>
					<p className='content'>
						Analyze market data to identify regions with high demand for jewelry
						and wedding wear. Focus your efforts on areas with the greatest
						sales potential.
					</p>
				</div>
				<div>
					<img src={audience} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						Customer{' '}
						<span className='higlightText'> Experience Enhancement</span>
					</h2>
					<p className='content'>
						Offer personalized shopping experiences based on customer
						preferences and buying behavior. Create memorable moments that drive
						repeat business and loyalty.
					</p>
				</div>
				<div>
					<img src={store} alt='' />
				</div>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						Seasonal <span className='higlightText'> Trend Insights</span>
					</h2>
					<p className='content'>
						Stay ahead of seasonal trends by monitoring customer preferences and
						purchasing patterns. Stock the right products to meet demand during
						peak wedding seasons.
					</p>
				</div>
				<div>
					<img src={benchmarking} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						Strategic <span className='higlightText'> Promotions </span>
					</h2>
					<p className='content'>
						Use data-driven insights to design targeted promotional campaigns.
						Attract brides and grooms-to-be with offers that align with their
						tastes and wedding plans.
					</p>
				</div>
				<div>
					<img src={promotion} alt='' />
				</div>
			</div>

			<ClientsSay />
			<div style={{ marginBottom: '6rem' }}>
				<ClientList />
			</div>
			{isOpenForm && (
				<DemoForm
					setDemo={setIsOpenForm}
					isCaseStudyRequested={true}
					caseStudyRequested={'malls'}
				/>
			)}
			<Footer />
		</>
	)
}

export default JewelleryWeddingWear
