import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/innerPage.css'
import styles from '../styles/restraunt.module.css'
import audience from '../images/qsr/1.png'
import store from '../images/qsr/2.png'
import benchmarking from '../images/qsr/3.png'
import promotion from '../images/qsr/4.png'
import qsr1 from '../images/qsr/slider/qsr1.png'
import qsr2 from '../images/qsr/slider/qsr2.png'
import ClientsSay from '../components/ClientsSay'
import ClientList from '../components/ClientList'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

function QSR() {
	return (
		<>
			<Header />
			<div className='header'>
				<h1>Precision Insights for Rapid Restraunt Expansion</h1>
				<p>
					Accelerate your QSR expansion with pinpoint location analysis,
					ensuring optimal catchments and minimizing cannibalization. Make
					data-driven decisions to enter new markets faster, maximize store
					performance, and drive sustainable growth.
				</p>
			</div>
			{/* <Carousel
				showArrows={false}
				autoPlay={true}
				showIndicators={false}
				infiniteLoop
				showStatus={false}
				showThumbs={false}
				dynamicHeight={true}>
				<div>
					<img src={qsr1} alt='qsr1' className={styles['carousel-img1']} />
				</div>
				<div>
					<img src={qsr2} alt='qsr2' className={styles['carousel-img2']} />
				</div>
			</Carousel> */}
			<div className='imgLeftContainer'>
				<div>
					<h2>
						<span className='higlightText'>Prime Location </span> Selection
					</h2>
					<p className='content'>
						Identify optimal locations for new QSR outlets using foot traffic
						and demographic data. Ensure high visibility and accessibility to
						attract more customers.
					</p>
				</div>
				<div>
					<img src={audience} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						Menu <span className='higlightText'> Personalization</span>
					</h2>
					<p className='content'>
						Use customer data to tailor menu offerings based on regional tastes
						and preferences. Enhance customer satisfaction by providing the food
						items they love most.
					</p>
				</div>
				<div>
					<img src={store} alt='' />
				</div>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						Operational <span className='higlightText'> Efficiency</span>
					</h2>
					<p className='content'>
						Streamline operations by understanding peak hours and customer flow
						patterns. Optimize staff allocation and inventory levels to meet
						demand efficiently.
					</p>
				</div>
				<div>
					<img src={benchmarking} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						Targeted <span className='higlightText'> Advertising </span>
					</h2>
					<p className='content'>
						Leverage location-based insights to run effective marketing
						campaigns. Reach your target audience with promotions that drive
						foot traffic and increase sales.
					</p>
				</div>
				<div>
					<img src={promotion} alt='' />
				</div>
			</div>
			<ClientsSay />
			<div style={{ marginBottom: '6rem' }}>
				<ClientList />
			</div>
			<Footer />
		</>
	)
}

export default QSR
