import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/innerPage.css'
import audience from '../images/sports/1.png'
import store from '../images/sports/2.png'
import benchmarking from '../images/sports/3.png'
import promotion from '../images/sports/4.png'
import sports1 from '../images/sports/slider/sports1.png'
import sports2 from '../images/sports/slider/sports2.png'
import ClientsSay from '../components/ClientsSay'
import ClientList from '../components/ClientList'
import { Carousel } from 'react-responsive-carousel'
import styles from '../styles/sports.module.css'

function SportsAthleisure() {
	return (
		<>
			<Header />
			<div className='header'>
				<h1>Discover Active Hotspots</h1>
				<p>
					Leverage data to identify key areas rich in fitness enthusiasts. Our
					insights empower your retail expansion by optimizing store placements
					and targeted promotions, ensuring you engage active consumers and
					build lasting brand loyalty.
				</p>
			</div>

			{/* <div className='image-container'>
				<img src={sports1} alt='' className='image firstImage' />
				<img src={sports2} alt='' className='image secondImage' />
			</div> */}

			{/* <Carousel
				showArrows={false}
				autoPlay={true}
				showIndicators={false}
				infiniteLoop
				showStatus={false}
				showThumbs={false}
				dynamicHeight={true}>
				<div>
					<img src={sports1} alt='qsr1' className={styles['carousel-img1']} />
				</div>
				<div>
					<img src={sports2} alt='qsr2' className={styles['carousel-img2']} />
				</div>
			</Carousel> */}

			<div className='imgLeftContainer'>
				<div>
					<h2>
						Optimal <span className='higlightText'> Store Locations </span>
					</h2>
					<p className='content'>
						Find the best locations for sports and athleisure stores using
						detailed location and consumer data. Ensure high visibility and
						accessibility to target fitness enthusiasts.
					</p>
				</div>
				<div>
					<img src={audience} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						Customer <span className='higlightText'> Behavior Insights</span>
					</h2>
					<p className='content'>
						Understand the preferences and buying habits of sports and
						athleisure customers. Tailor your product offerings to meet their
						needs and boost sales.
					</p>
				</div>
				<div>
					<img src={store} alt='' />
				</div>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						Inventory <span className='higlightText'> Optimization</span>
					</h2>
					<p className='content'>
						Use real-time data to manage inventory levels effectively. Ensure
						popular sports and athleisure products are always in stock to meet
						customer demand.
					</p>
				</div>
				<div>
					<img src={benchmarking} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						<span className='higlightText'> Effective Marketing </span>{' '}
						Campaigns
					</h2>
					<p className='content'>
						Implement targeted marketing strategies based on customer insights.
						Reach your audience with personalized promotions that drive
						engagement and increase sales.
					</p>
				</div>
				<div>
					<img src={promotion} alt='' />
				</div>
			</div>

			<ClientsSay />
			<div style={{ marginBottom: '6rem' }}>
				<ClientList />
			</div>

			<Footer />
		</>
	)
}

export default SportsAthleisure
