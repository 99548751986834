import React from 'react'
import '../styles/ClientsSay.css'
import { SwiperSlide, Swiper } from 'swiper/react'
// import { Pagination } from 'swiper/modules';
import quote from '../images/quote-left.svg'
import rehan from '../images/clientSay/Rehanhuck.jpg'
import ravinder from '../images/clientSay/RavinderChoudhary.jpg'
import pranav from '../images/clientSay/PranavBajaj.jpg'
import zenma from '../images/clientSay/SheenaKhurana.jpg'
import brahma from '../images/clientSay/brahma.png'
import openqoute from '../images/openqoute.svg'
import closeqoute from '../images/closeqoute1.svg'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

function ClientsSay() {
	return (
		<div className='testimonial-section'>
			<h2 className='heading'>What our clients say</h2>

			<Swiper
				// slidesPerView={3}
				spaceBetween={15}
				centeredSlides={true}
				autoplay={true}
				loop={true}
				breakpoints={{
					768: {
						slidesPerView: 2.5,
						spaceBetween: 30,
					},
					1200: {
						slidesPerView: 3.5,
						spaceBetween: 50,
					},
				}}
				// pagination={{
				//   clickable: true,
				// }}
				// modules={[Pagination]}
				className='clientSwiper'>
				<SwiperSlide className='new-slider'>
					<div className='slider-content1'>
						<img src={rehan} alt='' className='profileImg dp' />

						<div>
							<h3>Rehan Huck</h3>
							<h5>Vice President, DLF Malls</h5>
							<p>
								<img src={openqoute} alt='' />
								Sociometrik's dynamic catchment insights have been a
								game-changer for us to deeply understand our catchment audiences
								and build our brand mix in a data-driven manner. Their insights
								on consumer behaviour and retail category preferences have been
								really useful in creating a retail product that truly caters to
								the needs and preferences of our catchment residents
								<img src={closeqoute} alt='' className='closeqoute' />
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide className='new-slider'>
					<div className='slider-content1'>
						<img src={ravinder} alt='' className='profileImg dp' />

						<div>
							<h3>Ravinder Choudhary</h3>
							<h5>Vice President, Unity Group (VEGAS Malls)</h5>
							<p>
								<img src={openqoute} alt='' />
								Sociometrik's insights on visitor profiles coming to our malls
								have given us some sort of a superpower to deeply understand our
								visitors better than ever. We can now easily understand where
								our visitors are coming from, what consumer profiles are
								visiting us and a lot more. This has made our marketing, event
								planning & overall operations a lot more targeted and sharper
								<img src={closeqoute} alt='' className='closeqoute' />
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide className='new-slider'>
					<div className='slider-content1'>
						<img src={brahma} alt='' className='profileImg dp' />

						<div>
							<h3>Aparajita Rajpal</h3>
							<h5>AVP - Marketing & Leasing - Brahma Group</h5>
							<p>
								<img src={openqoute} alt='' />
								Sociometrik’s comprehensive data reports were pivotal in
								enhancing our understanding of the local market as we prepare to
								launch our new project in Gurugram. The insights provided a deep
								dive into consumer behavior, preferences, competitive landscape,
								and demographic trends within our primary catchment area,
								significantly informing our strategy for the upcoming project
								<img src={closeqoute} alt='' className='closeqoute' />
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide className='new-slider'>
					<div className='slider-content1'>
						<img src={pranav} alt='' className='profileImg dp' />

						<div>
							<h3>Pranav Bajaj</h3>
							<h5>Co-Founder, Medulance</h5>
							<p>
								<img src={openqoute} alt='' />
								Sociometrik is helping us on our journey to revolutionize
								emergency response services. Their location intelligence offer
								crucial insights into high-demand areas, optimizing our resource
								allocation. By analyzing demographic and mobility data, we
								identified regions and places requiring enhanced coverage. We
								are excited to continue exploring their capabilities, allowing
								us to serve communities better
								<img src={closeqoute} alt='' className='closeqoute' />
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide className='new-slider'>
					<div className='slider-content1'>
						<img src={zenma} alt='' className='profileImg dp' />

						<div>
							<h3>Sheena Khurana</h3>
							<h5>Co-Founder, Zenma</h5>
							<p>
								<img src={openqoute} alt='' />
								Partnering with Sociometrik has transformed Zenma Coffee's
								market strategy. By analyzing foot traffic to current brands and
								information on consumer profiles and behaviors, we identified
								ideal regions for growth. Additionally, their platform enabled
								us to develop highly targeted marketing campaigns. Their
								data-driven approach has been instrumental in our growth and
								success
								<img src={closeqoute} alt='' className='closeqoute' />
							</p>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	)
}

export default ClientsSay
