// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".restraunt_image-container__azO2w {\n\tposition: relative;\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n}\n\n.restraunt_image1__AjG6l {\n\tposition: absolute;\n\ttop: 0px;\n\twidth: 50% !important;\n}\n\n.restraunt_image2__jQkiQ {\n\tposition: absolute;\n\tbottom: 10px;\n\twidth: 50% !important;\n}\n\n.restraunt_carousel-img1__Sj-Yx,\n.restraunt_carousel-img2__rxOuA {\n\theight: 500px;\n}\n\n@media screen and (max-width: 600px) {\n\t.restraunt_carousel-img1__Sj-Yx {\n\t\theight: 300px;\n\t}\n\t.restraunt_carousel-img2__rxOuA {\n\t\theight: 300px;\n\t}\n}\n", "",{"version":3,"sources":["webpack://./src/styles/restraunt.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,WAAW;CACX,YAAY;CACZ,aAAa;CACb,uBAAuB;AACxB;;AAEA;CACC,kBAAkB;CAClB,QAAQ;CACR,qBAAqB;AACtB;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,qBAAqB;AACtB;;AAEA;;CAEC,aAAa;AACd;;AAEA;CACC;EACC,aAAa;CACd;CACA;EACC,aAAa;CACd;AACD","sourcesContent":[".image-container {\n\tposition: relative;\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n}\n\n.image1 {\n\tposition: absolute;\n\ttop: 0px;\n\twidth: 50% !important;\n}\n\n.image2 {\n\tposition: absolute;\n\tbottom: 10px;\n\twidth: 50% !important;\n}\n\n.carousel-img1,\n.carousel-img2 {\n\theight: 500px;\n}\n\n@media screen and (max-width: 600px) {\n\t.carousel-img1 {\n\t\theight: 300px;\n\t}\n\t.carousel-img2 {\n\t\theight: 300px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-container": "restraunt_image-container__azO2w",
	"image1": "restraunt_image1__AjG6l",
	"image2": "restraunt_image2__jQkiQ",
	"carousel-img1": "restraunt_carousel-img1__Sj-Yx",
	"carousel-img2": "restraunt_carousel-img2__rxOuA"
};
export default ___CSS_LOADER_EXPORT___;
