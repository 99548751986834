// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sports_carousel-img1__iEzGb,\n.sports_carousel-img2__V-xl9 {\n\theight: 500px;\n}\n\n@media screen and (max-width: 600px) {\n\t.sports_carousel-img1__iEzGb {\n\t\theight: 200px;\n\t}\n\t.sports_carousel-img2__V-xl9 {\n\t\theight: 200px;\n\t}\n}\n", "",{"version":3,"sources":["webpack://./src/styles/sports.module.css"],"names":[],"mappings":"AAAA;;CAEC,aAAa;AACd;;AAEA;CACC;EACC,aAAa;CACd;CACA;EACC,aAAa;CACd;AACD","sourcesContent":[".carousel-img1,\n.carousel-img2 {\n\theight: 500px;\n}\n\n@media screen and (max-width: 600px) {\n\t.carousel-img1 {\n\t\theight: 200px;\n\t}\n\t.carousel-img2 {\n\t\theight: 200px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel-img1": "sports_carousel-img1__iEzGb",
	"carousel-img2": "sports_carousel-img2__V-xl9"
};
export default ___CSS_LOADER_EXPORT___;
