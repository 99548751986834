import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/innerPage.css'
import styles from '../styles/Malls.module.css'
import audience from '../images/malls/1.png'
import store from '../images/malls/2.png'
import benchmarking from '../images/malls/3.png'
import promotion from '../images/malls/4.png'
import mall1 from '../images/malls/slider/mall1.png'
import mall2 from '../images/malls/slider/mall2.png'
import mall3 from '../images/malls/slider/mall3.png'
import mallsCaseStudy from '../images/malls/casestudymalls.png'
import ClientsSay from '../components/ClientsSay'
import ClientList from '../components/ClientList'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import DemoForm from '../components/DemoForm'

function Malls() {
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		arrows: false,
	}

	const [isOpenForm, setIsOpenForm] = useState(false)

	return (
		<>
			<Header />
			<div className='header'>
				<h1>Real-world visitor insights & Dynamic catchments</h1>
				<p className='desc'>
					Optimize tenant mix, build better event calendars, and run targeted
					marketing campaigns using data-driven insights on mall visitors and
					local residents. Boost footfall, increase visitor satisfaction, and
					maximize revenue.
				</p>
				{/* <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </p> */}
			</div>

			{/* <div className={styles['image-container']}>
				<img
					src={mall1}
					alt=''
					className={`${styles['image']} ${styles['firstImage']}`}
				/>
				<img
					src={mall2}
					alt=''
					className={`${styles['image']} ${styles['secondImage']}`}
				/>
				<img
					src={mall3}
					alt=''
					className={`${styles['image']} ${styles['thirdImage']}`}
				/>
			</div>

			<div
				className={`${styles['slider-container']} ${styles['mobile-slider']}`}>
				<Slider {...settings}>
					<img src={mall1} alt='' className={styles['slick-img']} />

					<img src={mall2} alt='' className={styles['slick-img']} />

					<img
						src={mall3}
						alt=''
						className={`${styles['slick-img']} ${styles['image-slider']}`}
					/>
				</Slider>
			</div> */}

			<div className='casestudy'>
				<div className='case-left'>
					<p className='title-case-study'>
						Case <span>Study</span>
					</p>
					<div className='inner-flex'>
						{' '}
						<h2>
							Increasing footfall & dwell time for a Leading Mall developer{' '}
						</h2>
						<p className='case-desc'>
							Faced with evolving consumer preferences and a need to revitalize
							its tenant mix, a mall turned to Sociometrik’s location data
							intelligence for insights on visitor demographics, catchment
							trends, and competitor activity. This enabled the mall to optimize
							store placement, plan targeted marketing campaigns, and boost
							footfall through data-driven decisions tailored to their core
							audience.
						</p>
					</div>
					<button
						className='btn-case-study'
						onClick={() => setIsOpenForm(true)}>
						Read Full Case Study
					</button>
				</div>

				<img
					src={mallsCaseStudy}
					alt='malls-case-study'
					height={400}
					className={styles['case-img']}
				/>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						Enhanced <span className='higlightText'>Audience Profiling</span>
					</h2>
					<p className='content'>
						Leverage our data intelligence to gain deep insights into your mall
						visitors demographics, behaviours, and preferences. Tailor marketing
						strategies and brand offerings to match their needs, driving
						engagement and sales.
					</p>
				</div>
				<div>
					<img src={audience} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						<span className='higlightText'>Optimised Store</span> Mix
					</h2>
					<p className='content'>
						Use our dynamic data on consumer trends and local demand to optimize
						your mall's retail mix. Ensure the right brands and categories are
						represented, enhancing customer satisfaction and increasing
						footfall.
					</p>
				</div>
				<div>
					<img src={store} alt='' />
				</div>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						Competitive <span className='higlightText'>Benchmarking</span>
					</h2>
					<p className='content'>
						Benchmark your mall's performance against competitors using
						real-time data. Understand market positioning, identify gaps, and
						make informed decisions to stay ahead in the retail landscape.
					</p>
				</div>
				<div>
					<img src={benchmarking} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						<span className='higlightText'>Event and Promotion </span>{' '}
						Effectiveness
					</h2>
					<p className='content'>
						Measure the impact of events and promotions on foot traffic and
						sales. Use actionable insights to plan future activities that
						resonate with your target audience, ensuring maximum ROI for
						marketing spends.
					</p>
				</div>
				<div>
					<img src={promotion} alt='promotion' className='case-img' />
				</div>
			</div>

			<ClientsSay />
			<div style={{ marginBottom: '6rem' }}>
				<ClientList />
			</div>
			{isOpenForm && (
				<DemoForm
					setDemo={setIsOpenForm}
					isCaseStudyRequested={true}
					caseStudyRequested={'malls'}
				/>
			)}
			<Footer />
		</>
	)
}

export default Malls
