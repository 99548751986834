// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Jewel_carousel-img1__Ty6Iv,\n.Jewel_carousel-img2__lGOxx {\n\theight: 500px;\n}\n\n@media only screen and (max-width: 1500px) {\n\t.Jewel_image-container__25Ipp {\n\t\theight: 750px;\n\t}\n\t.Jewel_case-img__Wrv4M {\n\t\theight: 300px;\n\t}\n}\n\n@media only screen and (max-width: 1300px) {\n\t.Jewel_image-container__25Ipp {\n\t\theight: 750px;\n\t}\n\t.Jewel_case-img__Wrv4M {\n\t\twidth: 100%;\n\t\theight: auto;\n\t}\n}\n\n@media screen and (max-width: 600px) {\n\t.Jewel_carousel-img1__Ty6Iv {\n\t\theight: 200px;\n\t}\n\t.Jewel_carousel-img2__lGOxx {\n\t\theight: 300px;\n\t}\n}\n", "",{"version":3,"sources":["webpack://./src/styles/Jewel.module.css"],"names":[],"mappings":"AAAA;;CAEC,aAAa;AACd;;AAEA;CACC;EACC,aAAa;CACd;CACA;EACC,aAAa;CACd;AACD;;AAEA;CACC;EACC,aAAa;CACd;CACA;EACC,WAAW;EACX,YAAY;CACb;AACD;;AAEA;CACC;EACC,aAAa;CACd;CACA;EACC,aAAa;CACd;AACD","sourcesContent":[".carousel-img1,\n.carousel-img2 {\n\theight: 500px;\n}\n\n@media only screen and (max-width: 1500px) {\n\t.image-container {\n\t\theight: 750px;\n\t}\n\t.case-img {\n\t\theight: 300px;\n\t}\n}\n\n@media only screen and (max-width: 1300px) {\n\t.image-container {\n\t\theight: 750px;\n\t}\n\t.case-img {\n\t\twidth: 100%;\n\t\theight: auto;\n\t}\n}\n\n@media screen and (max-width: 600px) {\n\t.carousel-img1 {\n\t\theight: 200px;\n\t}\n\t.carousel-img2 {\n\t\theight: 300px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel-img1": "Jewel_carousel-img1__Ty6Iv",
	"carousel-img2": "Jewel_carousel-img2__lGOxx",
	"image-container": "Jewel_image-container__25Ipp",
	"case-img": "Jewel_case-img__Wrv4M"
};
export default ___CSS_LOADER_EXPORT___;
