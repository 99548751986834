import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/innerPage.css'
import styles from '../styles/HomeLifestyle.module.css'
import audience from '../images/fashion/1.png'
import store from '../images/fashion/2.png'
import benchmarking from '../images/fashion/3.png'
import promotion from '../images/fashion/4.png'
import home1 from '../images/home/1.png'
import home2 from '../images/home/2.png'
import ClientsSay from '../components/ClientsSay'
import ClientList from '../components/ClientList'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function HomeLifestyle() {
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		arrows: false,
	}
	return (
		<>
			<Header />
			<div className='header'>
				<h1>Discover Premium Home & Lifestyle Markets</h1>
				<p>
					Identify key areas with high demand for home living, premium luggage,
					decor, and gifting. Our insights empower you to strategically position
					your brand, connecting with discerning consumers who prioritize
					quality and style.
				</p>
			</div>

			{/* <div className={styles['image-container']}>
				<img
					src={home1}
					alt=''
					className={`${styles['image']} ${styles['firstImage']}`}
				/>
				<img
					src={home2}
					alt=''
					className={`${styles['image']} ${styles['secondImage']}`}
				/>
			</div> */}

			{/* <div
				className={[styles['slider-container'], styles['mobile-slider']].join(
					' ',
				)}>
				<Slider {...settings}>
					<img src={home1} alt='' className={styles['slick-img']} />

					<img
						src={home2}
						alt=''
						className={`${styles['slick-img']} ${styles['image-slider-2']}`}
					/>
				</Slider>
			</div> */}

			<div className='imgLeftContainer'>
				<div>
					<h2>
						<span className='higlightText'>Targeted Store </span> Placement
					</h2>
					<p className='content'>
						Utilize location and consumer data to identify prime locations for
						new fashion stores. Ensure maximum foot traffic and visibility by
						targeting areas with high potential customer density.
					</p>
				</div>
				<div>
					<img src={audience} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						Customer <span className='higlightText'> Insights and Trends</span>
					</h2>
					<p className='content'>
						Gain deep insights into customer preferences, purchasing behaviors,
						and fashion trends. Tailor your product offerings to meet evolving
						consumer demands and stay ahead of the competition.
					</p>
				</div>
				<div>
					<img src={store} alt='' />
				</div>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						Optimized{' '}
						<span className='higlightText'> Inventory Management</span>
					</h2>
					<p className='content'>
						Use real-time data to manage your inventory effectively. Ensure the
						right products are available at the right time, reducing overstock
						and stockouts, and improving customer satisfaction.
					</p>
				</div>
				<div>
					<img src={benchmarking} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						<span className='higlightText'>Enhanced Marketing </span> Campaigns
					</h2>
					<p className='content'>
						Leverage data intelligence to create personalized marketing
						campaigns. Target specific consumer segments with tailored
						promotions, driving engagement, brand loyalty, and sales growth..
					</p>
				</div>
				<div>
					<img src={promotion} alt='' />
				</div>
			</div>

			<ClientsSay />
			<div style={{ marginBottom: '6rem' }}>
				<ClientList />
			</div>

			<Footer />
		</>
	)
}

export default HomeLifestyle
