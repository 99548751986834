import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import '../styles/DemoForm.css'
import { useState } from 'react'
import axios from 'axios'
import env from '@beam-australia/react-env'
import logo from '../images/footerlogo.png'
function DemoForm(props) {
	const [submit, setSubmit] = useState(false)
	const [submitButton, setSubmitButton] = useState(false)
	const [demoData, setDemoData] = useState({
		fname: '',
		lname: '',
		company: '',
		email: '',
		pnumber: '',
		teams: '',
	})
	const [selectOpen, setSelectOpen] = useState(false)

	useEffect(() => {
		if (demoData.fname && demoData.company && demoData.email) {
			setSubmitButton(true)
		} else {
			setSubmitButton(false)
		}
	}, [demoData])

	function handleClick() {
		props.setDemo(false)
	}

	function handleChange(e) {
		const { name, value } = e.target
		setDemoData((pValue) => {
			return {
				...pValue,
				[name]: value,
			}
		})
		console.log(demoData)
	}

	function handleSubmit(e) {
		e.preventDefault()
		setSubmit(true)
	}

	function selectOption(e) {
		setDemoData((pValue) => {
			return {
				...pValue,
				teams: e.target.innerText,
			}
		})
		setSelectOpen(false)
	}

	function openSelect() {
		setSelectOpen(true)
	}

	const windowAnim = {
		hidden: { opacity: 0 },
		visible: { opacity: 0.8, transition: { duration: 0.4 } },
	}

	const formAnim = {
		hidden: { position: 'relative', bottom: '-60px', opacity: 0 },
		visible: {
			position: 'relative',
			bottom: '0px',
			opacity: 1,
			transition: { duration: 0.6 },
		},
	}
	async function handleResponse() {
		const postFormData = new FormData()

		postFormData.append('fname', demoData.fname)
		postFormData.append('lname', demoData.lname)
		postFormData.append('email', demoData.email)
		postFormData.append('pnumber', demoData.pnumber)
		postFormData.append('teams', demoData.teams)
		postFormData.append('company', demoData.company)
		postFormData.append('isCaseStudyRequested', props?.isCaseStudyRequested)
		postFormData.append('caseStudyRequested', props?.caseStudyRequested)
		await axios.post(env('EMAIL_URL'), postFormData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}
	return (
		<>
			<motion.div
				variants={windowAnim}
				initial='hidden'
				animate='visible'
				className='demo-form-window'></motion.div>
			<div className='demo-form-container'>
				<motion.div
					variants={formAnim}
					initial='hidden'
					animate='visible'
					// style={{ height: submit ? '457px' : '499px' }}
					className='demo-form'>
					<div onClick={handleClick} className='close-button'>
						<img src={require('../images/close-button.png')} alt='' />
					</div>
					{submit ? (
						<div className='form-submitted'>
							<img src={require('../images/form-submitted.png')} alt='' />
							<p>
								Your request has been successfully submitted. Our team will
								reach out to you.
							</p>
							<button onClick={handleClick}>Back to homepage</button>
						</div>
					) : (
						<>
							<div className='top-container'>
								<img src={logo} alt='' width={200} />
								<span style={{ textAlign: 'center' }}>
									See how our location intelligence enhances your retail
									strategy
								</span>
							</div>
							<form onSubmit={handleSubmit}>
								<div className='form-grid'>
									<div className='form-field'>
										<h3>Full name *</h3>
										<input
											onChange={handleChange}
											type='text'
											name='fname'
											placeholder='Enter your full name'
											required
										/>
									</div>
									{/* <div className='form-field'>
										<h3>Last name *</h3>
										<input
											onChange={handleChange}
											type='text'
											name='lname'
											placeholder='Enter your last name'
											required
										/>
									</div> */}
									<div className='form-field'>
										<h3>Company name *</h3>
										<input
											onChange={handleChange}
											type='text'
											name='company'
											placeholder='Enter your company name'
											required
										/>
									</div>
									<div className='form-field'>
										<h3>Designation</h3>
										<div className='select-menu'>
											<div className='input-icon'>
												<input
													className='select-input'
													placeholder='Enter your designation'
													// value={demoData.teams}
													onChange={handleChange}
													type='text'
													name='teams'
												/>
											</div>
										</div>
									</div>
									<div className='form-field'>
										<h3>Email *</h3>
										<input
											onChange={handleChange}
											type='email'
											name='email'
											placeholder='Enter your email address'
											required
										/>
									</div>
									<div className='form-field'>
										<h3>Phone Number</h3>
										<input
											onChange={handleChange}
											type='text'
											name='pnumber'
											placeholder='Enter your phone number'
										/>
									</div>
								</div>
								<button
									className={
										submitButton ? 'enabled-button' : 'disabled-button'
									}
									disabled={!submitButton}
									onClick={handleResponse}>
									{props?.isCaseStudyRequested
										? 'Submit and get requested case Study'
										: 'Submit and get a call back'}
								</button>
							</form>
						</>
					)}
				</motion.div>
			</div>
		</>
	)
}

export default DemoForm
