import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/innerPage.css'
import audience from '../images/beauty/1.png'
import store from '../images/beauty/2.png'
import benchmarking from '../images/beauty/3.png'
import promotion from '../images/beauty/4.png'
import beauty1 from '../images/beauty/slider/beauty1.png'
import beauty2 from '../images/beauty/slider/beauty2.png'
import beauty3 from '../images/beauty/slider/beauty3.png'
import ClientsSay from '../components/ClientsSay'
import ClientList from '../components/ClientList'
import styles from '../styles/Beauty.module.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function BeautySkincare() {
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		arrows: false,
	}
	return (
		<>
			<Header />
			<div className='header'>
				<h1>Premium Insights for Expanding Beauty & Skincare Brands</h1>
				<p>
					Tap into India’s growing beauty market with data-driven strategies for
					premium segment expansion. Identify high-potential locations, tailor
					product offerings, and reach your ideal customer base with precision.
					Maximize growth and brand loyalty with smarter retail decisions.
				</p>
			</div>

			{/* <div className={styles['image-container']}>
				<img
					src={beauty1}
					alt=''
					className={`${styles['image']} ${styles['firstImage']}`}
				/>
				<img
					src={beauty3}
					alt=''
					className={`${styles['image']} ${styles['secondImage']}`}
				/>
				<img
					src={beauty2}
					alt=''
					className={`${styles['image']} ${styles['thirdImage']}`}
				/>
			</div>

			<div
				className={[styles['slider-container'], styles['mobile-slider']].join(
					' ',
				)}>
				<Slider {...settings}>
					<img src={beauty1} alt='' className={styles['slick-img']} />

					<img
						src={beauty2}
						alt=''
						className={`${styles['slick-img']} ${styles['image-slider-2']}`}
					/>

					<img
						src={beauty3}
						alt=''
						className={`${styles['slick-img']} ${styles['image-slider']}`}
					/>
				</Slider>
			</div> */}

			<div className='imgLeftContainer'>
				<div>
					<h2>
						<span className='higlightText'>Localized Product </span> Launches
					</h2>
					<p className='content'>
						Launch new beauty and skincare products in areas with the highest
						demand. Use location-based data to understand regional preferences
						and ensure successful product introductions.
					</p>
				</div>
				<div>
					<img src={audience} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						Customer <span className='higlightText'> Profile Segmentation</span>
					</h2>
					<p className='content'>
						Segment customers based on detailed demographic and behavioral data.
						Create personalized beauty and skincare experiences tailored to the
						unique needs of different customer groups.
					</p>
				</div>
				<div>
					<img src={store} alt='' />
				</div>
			</div>

			<div className='imgLeftContainer'>
				<div>
					<h2>
						Optimized <span className='higlightText'> Shelf Space</span>
					</h2>
					<p className='content'>
						Allocate shelf space based on real-time sales data and customer
						preferences. Maximize product visibility and boost sales by stocking
						the most in-demand items.
					</p>
				</div>
				<div>
					<img src={benchmarking} alt='' />
				</div>
			</div>

			<div className='imgRightContainer'>
				<div>
					<h2>
						Targeted <span className='higlightText'> Promotions </span>
					</h2>
					<p className='content'>
						Implement data-driven marketing campaigns that resonate with your
						audience. Use insights to deliver the right promotions at the right
						time, increasing customer engagement and sales.
					</p>
				</div>
				<div>
					<img src={promotion} alt='' />
				</div>
			</div>

			<ClientsSay />
			<div style={{ marginBottom: '6rem' }}>
				<ClientList />
			</div>

			<Footer />
		</>
	)
}

export default BeautySkincare
